import { css } from "@emotion/react";
// import { auth } from "auth/azure";
import React from "react";
import tw from "twin.macro";
import { FacebookIcon, GoogleIcon, MicrosoftIcon } from ".";
import { config } from "../../config";
export const GroupSocialNetwork: React.FC = () => {
  const mediaIcons = [
    // {
    //   icon: <FacebookIcon />,
    //   title: "Facebook",
    //   link: `${config.API_URL}/auth/facebook`,
    // },
    {
      icon: <GoogleIcon />,
      title: "Google",
      link: `${config.API_URL}/auth/google`,
    },
    // {
    //   icon: <MicrosoftIcon />,
    //   title: "Microsoft",
    //   cb: () => auth.loginRedirect()
    // },
  ];

  return (
    <div
      className="w-fit space-x-2 j-between mx-auto "
      css={css`
        a {
          ${tw`block w-[45px]`}
          svg {
            border: 1px solid #008a9f85;
            border-radius: 50%;
            -moz-border-radius: 50%;
            -webkit-border-radius: 50%;
            padding: 10px;
            &:hover {
              // background: rgba(100, 0, 0, 0.03);
              background: rgba(217, 162, 42, 0.5);
              color: white !important;
            }
          }
        }
      `}
    >
      {mediaIcons.map((item) => {
        return (
          <a
            href={item.link || ""}
            key={item.title}
            onClick={(e) => {
              // if(item.title === 'Microsoft') {
              //   e.preventDefault();
              //   item.cb?.()
              // }
            }}
            className="fill-primary"
          >
            {item.icon}
          </a>
        );
      })}
    </div>
  );
};
