import React, { useState } from "react";
import "./dashboard.scoped.scss";
import routerProvider, { useParams } from "@pankod/refine-react-router-v6";
import logo from "assets/img/logologo.png";
import { HRASection } from "components/HRA";
import { RiskAreaImage } from "components/Risk";
import { ActionPlan } from "components/ActionPlan";
import { useMe } from "context/MeContext";
import { CrudFilters, useList } from "@pankod/refine-core";
import { IUser } from "types/user";
import { RewardBoard } from "components/RewardBoard";
import Lottie from "react-lottie-player";
import lottieJson1 from "./Lottie/lottie1.json";
import lottieJson2 from "./Lottie/lottie2.json";
import lottieJson3 from "./Lottie/lottie3.json";
import lottieJson4 from "./Lottie/lottie4.json";
import lottieJson5 from "./Lottie/lottie5.json";
import lottieJson7 from "./Lottie/lottie7.json";
import { alignmentTextToCss, config } from "config";
import { css } from "@emotion/react";
import { useCompany } from "context/CompanyContext";
import { generateURL } from "utils/generateURL";
import { Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBarsProgress, faChartColumn, faCheckDouble, faRankingStar } from "@fortawesome/free-solid-svg-icons";

const { Link } = routerProvider;
const Dashboard: React.FC = () => {
  const { path } = useParams();
  const [isPlay, setIsPlay] = React.useState(false);
  const { refetch, me: user, company, myHRA } = useMe();
  const { path: company_path } = useCompany();
  const { employer, brand } = useCompany();
  const [show1, setShow1] = useState(true);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);

  React.useEffect(() => {
    // check if users have not action tracking then system will create a tracking for them.
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // All-time Leaderboard
  const generalFilters: CrudFilters = [
    {
      field: "companyId",
      operator: "eq",
      value: company?.id,
    },
  ];
  const { data: dataUsers, refetch: refetchUsers } = useList<IUser>({
    resource: "users",
    config: {
      sort: [
        {
          field: "activityPoint",
          order: "desc",
        },
      ],
      pagination: {
        pageSize: 5,
      },
      filters: user?.hideMeDashboard
        ? [
            {
              field: "id",
              operator: "ne",
              value: user?.id,
            },
            ...generalFilters,
          ]
        : [...generalFilters],
    },
  });
  React.useEffect(() => {
    if (path || !company) return;
    window.history.replaceState(null, "", company?.path);
  }, [path, company]);

  const position = company?.portfolio?.banner?.position?.split("-");
  const horizontal = position?.[1] as any;
  const vertical = position?.[0] as any;
  const _logo = company?.portfolio?.logo ? config.ASSET_URL + company?.portfolio?.logo : logo;
  const banner = company?.portfolio?.banner?.banner_url;
  const bannerRes = company?.portfolio?.banner?.responsiveBanner;
  const dataUsersTotal = dataUsers?.data;
  const [count, setCount] = React.useState(0);
  const onRefetchUsers = () => {
    refetchUsers();
    refetch();
    setIsPlay(true);
    setCount((curr) => curr + 1);
  };
  const arr = [
    {
      animation: (
        <Lottie
          speed={1.5}
          loop={0}
          animationData={lottieJson1}
          play
          style={{ width: 1000, height: 1000 }}
          className="lottie"
          onComplete={() => {
            setIsPlay(false);
          }}
        />
      ),
      value: "lottie1",
    },
    {
      animation: (
        <Lottie
          speed={1.5}
          loop={0}
          animationData={lottieJson2}
          play
          // style={{ width: 1000, height: 1000 }}
          className="lottie lottieCheck"
          onComplete={() => {
            setIsPlay(false);
          }}
        />
      ),
      value: "lottie2",
    },
    {
      animation: (
        <Lottie
          speed={2.5}
          loop={0}
          animationData={lottieJson3}
          play
          // style={{ width: 1000, height: 1000 }}
          className="lottie lottieBear"
          onComplete={() => {
            setIsPlay(false);
          }}
        />
      ),
      value: "lottie3",
    },
    {
      animation: (
        <Lottie
          speed={1.5}
          loop={0}
          animationData={lottieJson4}
          play
          // style={{ width: 1000, height: 1000 }}
          className="lottie lottieCheck "
          onComplete={() => {
            setIsPlay(false);
          }}
        />
      ),
      value: "lottie4",
    },
    {
      animation: (
        <Lottie
          speed={1.5}
          loop={0}
          animationData={lottieJson5}
          play
          // style={{ width: 1000, height: 1000 }}
          className="lottie lottieCheck "
          onComplete={() => {
            setIsPlay(false);
          }}
        />
      ),
      value: "lottie5",
    },
    {
      animation: (
        <Lottie
          speed={2}
          loop={0}
          animationData={lottieJson7}
          play
          // style={{ width: 1000, height: 1000 }}
          className="lottie lottieCheck "
          onComplete={() => {
            setIsPlay(false);
          }}
        />
      ),
      value: "lottie7",
    },
  ];
  const icon_settings = company?.icon_setting?.settings || [];

  function randomLottie() {
    const new_arr = arr.filter((item) => icon_settings.includes(item.value));
    // if (new_arr.length === 0) return arr[0].animation;
    if (new_arr.length === 0) return 0;
    return new_arr[Math.floor(Math.random() * new_arr.length)].animation;
  }

  return (
    <div className="dashboard-page pageload" id="sd">
      {/* <h1>BANNER</h1> */}
      <div
        className="banner"
        css={css`
          background: url("${generateURL(banner)} ") no-repeat top center/cover;
          height: 100vh;
          margin: 0 auto;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          z-index: 1;
          @media (max-width: 660px) {
            background: url("${generateURL(bannerRes)}") no-repeat top center/cover !important;
            margin-top: 30px;
            height: 70vh !important;
            width: 100% !important;
            z-index: 0;
          }
        `}
      >
        <div
          className="banner-container container flex flex-col"
          css={css`
            height: calc(100% - 12%);
            align-items: ${alignmentTextToCss[horizontal]};
            justify-content: ${alignmentTextToCss[vertical]};
            @media (min-width: 578px) and (max-width: 991px) {
              width: 100% !important;
            }
          `}
        >
          <div
            className="school-title"
            css={css`
              color: ${employer?.color};
              font-size: ${employer?.font_size} !important;
            `}
          >
            {employer?.name}
          </div>
          <h3
            className="dashboard-slogan"
            css={css`
              color: ${employer?.tagline_color} !important;
              font-size: ${employer?.tagline_font_size}px !important;
              text-align: ${horizontal};
              @media screen and (max-width: 768px) {
                margin-top: 10px;
                font-size: 22px !important;
                letter-spacing: 0.5px !important;
                line-height: 25px !important;
              }
            `}
          >
            {employer?.tagline}
          </h3>
          <Link to={`${company_path}/contact`}>
            <button
              className="button-find"
              css={css`
                color: ${employer?.btn_contact_us_color} !important;
                border: 1px solid ${employer?.btn_contact_us_color};
                z-index: 100;
                @media screen and (max-width: 898px) {
                  display: none !important;
                }
              `}
            >
              Contact Us
            </button>
          </Link>
        </div>

        <Link to="#sd" className="container" style={{ zIndex: "1000" }}>
          <div className="waviy">
            <div className="up-arrow">&raquo;</div>
            <span className="E">E</span>
            <span className="X">X</span>
            <span className="P">P</span>
            <span className="L">L</span>
            <span className="O">O</span>
            <span className="R">R</span>
            <span className="E2">E</span>
            <span className="">&nbsp;</span>
            <span className="">&nbsp;</span>
            <span className="N">N</span>
            <span className="O2">O</span>
            <span className="W">W</span>
          </div>
        </Link>
      </div>

      <div>
        <div className="charts-content" id="sd">
          <div
            className="menu-mobile"
            css={css`
              // overflow-y: scroll;
              // white-space: nowrap;
              .active {
                color: ${brand?.color_primary};
              }
              li {
                text-align: center;
                align-items: center;
                font-size: 10px;
                line-height: 25px;
              }
            `}
          >
            <ul>
              <li
                className={show1 ? "active" : ""}
                onClick={() => {
                  setShow1(true);
                  setShow2(false);
                  setShow3(false);
                  setShow4(false);
                }}
              >
                <div className="icon">
                  <FontAwesomeIcon icon={faChartColumn} />
                </div>
                hra summary
              </li>
              <li
                className={show2 ? "active" : ""}
                onClick={() => {
                  setShow1(false);
                  setShow2(true);
                  setShow3(false);
                  setShow4(false);
                }}
              >
                <div className="icon">
                  <FontAwesomeIcon icon={faBarsProgress} />
                </div>
                risk areas
              </li>
              <li
                className={show3 ? "active" : ""}
                onClick={() => {
                  setShow1(false);
                  setShow2(false);
                  setShow3(true);
                  setShow4(false);
                }}
              >
                <div className="icon">
                  <FontAwesomeIcon icon={faCheckDouble} />
                </div>
                action plan progress
              </li>
              <li
                className={show4 ? "active" : ""}
                onClick={() => {
                  setShow1(false);
                  setShow2(false);
                  setShow3(false);
                  setShow4(true);
                }}
              >
                <div className="icon">
                  <FontAwesomeIcon icon={faRankingStar} />
                </div>
                leaderboard
              </li>
            </ul>
          </div>
          <div className="chart-box j-between intro-box container">
            <div className="intro-top">
              <h2>
                Hi, <span className="user-name">{user?.firstname || user?.email}</span>!
              </h2>
              <p>
                This page includes information about your HRA score as well as activities that can help you on your path
                to wellness.
              </p>
              <a
                target="_blank"
                rel="noreferrer noopener"
                href="https://monumenthealth.net/goodlife-tutorials/"
                css={css`
                  font-style: italic;
                  color: #999999;
                  &:hover {
                    color: var(--c-orange) !important;
                  }
                `}
              >
                Click here to watch a tutorial and enhance your understanding of the dashboard
              </a>
            </div>
            <div className="logo">
              <img src={_logo} alt="logo" />
            </div>
          </div>

          {/* HRA SUMMARY */}
          <div className={`chart container ${show1 ? "show-class" : "hide-class"}`} style={{ marginTop: 3 }}>
            {typeof myHRA !== "object" ? (
              <Link to={`/${company_path}/hra`} className="disabled-chart">
                <div className="disabled-group">
                  <p>Curious about diving deeper into your health stats? Take the HRA!</p>
                  <Button size="large">Let's Go!</Button>
                </div>
              </Link>
            ) : null}
            <HRASection />
          </div>

          {/* <div className="hr-responsive"></div> */}
          {/* RISK AREAS & IMAGE */}
          <div className={`chart container ${show2 ? "show-class" : "hide-class"}`}>
            <RiskAreaImage />
          </div>

          {/* ACTION PLAN PROGRESS */}
          <div className={`chart container ${show3 ? "show-class" : "hide-class"}`}>
            <ActionPlan onRefetchUsers={onRefetchUsers} />
          </div>

          {/* REWARD BOARD */}
          <div className={`container ${show4 ? "show-class" : "hide-class"}`}>
            <RewardBoard count={count} dataUsersTotal={dataUsersTotal} />
          </div>
        </div>
      </div>
      {isPlay && <div className="done-annimation">{randomLottie()}</div>}
    </div>
  );
};

export default Dashboard;
