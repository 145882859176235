import { DatePicker } from "antd";
import { UploadFiles } from "components/Upload/UploadFile";
import dayjs from "dayjs";
import React from "react";
import { StatTypeChildProps } from "../StatType";
import "../updateStats.scoped.scss"

export const DateType: React.FC<StatTypeChildProps> = ({
  data,
  userResult,
  setUserResult,
  week,
  onUpdateChild,
  order,
  disabled,
  files,
  onRemoveFile,
  setLoading,
  loading,
  callback,
}) => {
  const answer = userResult?.[week]?.[order]?.user_answer;
  const [date, setDate] = React.useState(answer);
  const onUpdate = async (value: number, user_answer: string) => {
    const _child = onUpdateChild?.(value, user_answer, data);
    const _userResult = {
      ...userResult,
      [week]: _child,
    };
    setUserResult(_userResult);
  };
  React.useEffect(() => {
    setDate(answer);
  }, [answer]);
  return (
    <div className="single-activity">
      <hr className="single-activity-hr" />
      <div className="j-between single-activity-group">
        <div className="show-on-responsive one">
          <div className="left-col col">{data.title}</div>
          <div className="center-col col">{data.description}</div>
        </div>
        <div className="left-col col show-on-lg">{data.title}</div>
        <div className="center-col col show-on-lg">{data.description}</div>
        <div className="right-col j-between col buttons two">
          <DatePicker
            onChange={(value) => {
              onUpdate(1, dayjs(value).format("YYYY-MM-DD"));
              setDate(value);
            }}
            value={date && date !== "Invalid Date" ? dayjs(date) : undefined}
            style={{ width: "100%" }}
            disabled={disabled}
          />
        </div>
        <div className="right-right-col col three">
          <UploadFiles
            key={files[files.length - 1]}
            isDisabled={disabled}
            loading={loading}
            setLoading={setLoading}
            callback={callback}
            onRemoveFile={onRemoveFile}
            files={files}
          />
        </div>
      </div>
    </div>
  );
};
