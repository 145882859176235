import React, { useState } from "react";
import "./dashboard.scoped.scss";
import routerProvider, { useParams } from "@pankod/refine-react-router-v6";
import logo from "assets/img/logologo.png";
import bannerRes from "assets/img/res-banner.png";
import { HRASection } from "components/HRA";
import { RiskAreaImage } from "components/Risk";
import { ActionPlan } from "components/ActionPlan";
import { useMe } from "context/MeContext";
import { useList } from "@pankod/refine-core";
import { IUser } from "types/user";
import { RewardBoard } from "components/RewardBoard";
import Lottie from "react-lottie-player";
import lottieJson1 from "./Lottie/lottie1.json";
import lottieJson2 from "./Lottie/lottie2.json";
import lottieJson3 from "./Lottie/lottie3.json";
import lottieJson4 from "./Lottie/lottie4.json";
import lottieJson5 from "./Lottie/lottie5.json";
import lottieJson7 from "./Lottie/lottie7.json";
import { alignmentTextToCss, config } from "config";
import { css } from "@emotion/react";
import { useCompany } from "context/CompanyContext";
import { generateURL } from "utils/generateURL";
import { Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; 
import { faBarsProgress, faChartColumn, faCheckDouble, faRankingStar } from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";

const { Link } = routerProvider;
const ActivityPage: React.FC = () => {
  const { path } = useParams();
  const [isPlay, setIsPlay] = React.useState(false);
  const { refetch, me: user, company, myHRA } = useMe();
  const { path: company_path } = useCompany();
  const { employer, brand } = useCompany();
  const [show3, setShow3] = useState(true);
  const [show4, setShow4] = useState(false);

  React.useEffect(() => {
    // check if users have not action tracking then system will create a tracking for them.
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // All-time Leaderboard
  const { data: dataUsers, refetch: refetchUsers } = useList<IUser>({
    resource: "users",
    config: {
      sort: [
        {
          field: "activityPoint",
          order: "desc",
        },
      ],
      pagination: {
        pageSize: 5,
      },
      filters: user?.hideMeDashboard
        ? [
            {
              field: "id",
              operator: "ne",
              value: user?.id,
            },
          ]
        : undefined,
    },
  });
  React.useEffect(() => {
    if (path || !company) return;
    window.history.replaceState(null, "", company?.path);
  }, [path, company]);

  console.log("company", company?.portfolio);
  console.log("HRA", myHRA);
  // if (myHRA === "undefined") return "undefinededededed"

  const position = company?.portfolio?.banner?.position?.split("-");
  const horizontal = position?.[1] as any;
  const vertical = position?.[0] as any;
  const _logo = company?.portfolio?.logo ? config.ASSET_URL + company?.portfolio?.logo : logo;
  const banner = company?.portfolio?.banner?.banner_url;
  const dataUsersTotal = dataUsers?.data;
  const [count, setCount] = React.useState(0);
  const onRefetchUsers = () => {
    refetchUsers();
    refetch();
    setIsPlay(true);
    setCount((curr) => curr + 1);
  };
  const arr = [
    {
      animation: (
        <Lottie
          speed={1.5}
          loop={0}
          animationData={lottieJson1}
          play
          style={{ width: 1000, height: 1000 }}
          className="lottie"
          onComplete={() => {
            setIsPlay(false);
          }}
        />
      ),
      value: "lottie1",
    },
    {
      animation: (
        <Lottie
          speed={1.5}
          loop={0}
          animationData={lottieJson2}
          play
          // style={{ width: 1000, height: 1000 }}
          className="lottie lottieCheck"
          onComplete={() => {
            setIsPlay(false);
          }}
        />
      ),
      value: "lottie2",
    },
    {
      animation: (
        <Lottie
          speed={2.5}
          loop={0}
          animationData={lottieJson3}
          play
          // style={{ width: 1000, height: 1000 }}
          className="lottie lottieBear"
          onComplete={() => {
            setIsPlay(false);
          }}
        />
      ),
      value: "lottie3",
    },
    {
      animation: (
        <Lottie
          speed={1.5}
          loop={0}
          animationData={lottieJson4}
          play
          // style={{ width: 1000, height: 1000 }}
          className="lottie lottieCheck "
          onComplete={() => {
            setIsPlay(false);
          }}
        />
      ),
      value: "lottie4",
    },
    {
      animation: (
        <Lottie
          speed={1.5}
          loop={0}
          animationData={lottieJson5}
          play
          // style={{ width: 1000, height: 1000 }}
          className="lottie lottieCheck "
          onComplete={() => {
            setIsPlay(false);
          }}
        />
      ),
      value: "lottie5",
    },
    {
      animation: (
        <Lottie
          speed={2}
          loop={0}
          animationData={lottieJson7}
          play
          // style={{ width: 1000, height: 1000 }}
          className="lottie lottieCheck "
          onComplete={() => {
            setIsPlay(false);
          }}
        />
      ),
      value: "lottie7",
    },
  ];
  const icon_settings = company?.icon_setting?.settings || [];

  function randomLottie() {
    const new_arr = arr.filter((item) => icon_settings.includes(item.value));
    // if (new_arr.length === 0) return arr[0].animation;
    if (new_arr.length === 0) return 0;
    return new_arr[Math.floor(Math.random() * new_arr.length)].animation;
  }

  return (
    <div className="activity-page pageload" id="sd">

      <div>
        <div className="charts-content" id="sd"> 
          <div
            className="menu-mobile"
            css={css`
              // overflow-y: scroll;
              // white-space: nowrap;
              .active {
                color: ${brand?.color_primary};
              }
              li {
                text-align: center;
                align-items: center;
                font-size: 10px;
                line-height: 25px;
              }
            `}
          >
            <ul>
              <li
                className={show3 ? "active" : ""}
                onClick={() => {
                  setShow3(true);
                  setShow4(false);
                }}
              >
                <div className="icon">
                  <FontAwesomeIcon icon={faCheckDouble} />
                </div>
                action plan progress
              </li>
              <li
                className={show4 ? "active" : ""}
                onClick={() => {
                  setShow3(false);
                  setShow4(true);
                }}
              >
                <div className="icon">
                  <FontAwesomeIcon icon={faRankingStar} />
                </div>
                leaderboard
              </li>
            </ul>
          </div>

          {/* ACTION PLAN PROGRESS */}
          <div className={`chart container ${show3 ? "show-class" : "hide-class"}`}>
            {typeof myHRA !== "object" ? (
              <Link to={`/${company_path}/hra`} className="disabled-chart disable-action-plan">
                <div className="disabled-group">
                  <p>Curious about diving deeper into your health stats? Take the HRA!</p>
                  <Button size="large">Let's Go!</Button>
                </div>
              </Link>
            ) : null}
            {/* <ActionPlanNew /> */}
            <ActionPlan onRefetchUsers={onRefetchUsers} />
          </div>

          {/* REWARD BOARD */}
          <div className={`container ${show4 ? "show-class" : "hide-class"}`}>
            <RewardBoard count={count} dataUsersTotal={dataUsersTotal} />
          </div>
        </div>
      </div>
      {isPlay && <div className="done-annimation">{randomLottie()}</div>}
    </div>
  );
};

export default ActivityPage;
