import React, { useState } from "react";
import { Checkbox, Divider, Spin } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
//import type { CheckboxValueType } from "antd/es/checkbox/Group";
import Lottie from "react-lottie-player";
import lottieJson1 from "../../Dashboard/Lottie/lottie1.json";
import lottieJson2 from "../../Dashboard/Lottie/lottie2.json";
import lottieJson3 from "../../Dashboard/Lottie/lottie3.json";
import lottieJson4 from "../../Dashboard/Lottie/lottie4.json";
import lottieJson5 from "../../Dashboard/Lottie/lottie5.json";
import lottieJson7 from "../../Dashboard/Lottie/lottie7.json"; 
import { css } from "@emotion/react";
import { useCreate, useList, useUpdate } from "@pankod/refine-core";
import { useMe } from "context/MeContext";
import { AnyCnameRecord } from "dns";

const CheckboxGroup = Checkbox.Group;

const defaultCheckedList = [""];

const options = [
  {
    label: (
      <Lottie speed={1.5} animationData={lottieJson1} play style={{ width: 200, height: 200 }} className="lottie" />
    ),
    value: "lottie1",
  },
  {
    label: (
      <Lottie speed={1.5} animationData={lottieJson2} play style={{ width: 200, height: 200 }} className="lottie" />
    ),
    value: "lottie2",
  },
  {
    label: (
      <Lottie speed={1.5} animationData={lottieJson3} play style={{ width: 200, height: 200 }} className="lottie" />
    ),
    value: "lottie3",
  },
  {
    label: (
      <Lottie speed={1.5} animationData={lottieJson4} play style={{ width: 200, height: 200 }} className="lottie" />
    ),
    value: "lottie4",
  },
  {
    label: (
      <Lottie speed={1.5} animationData={lottieJson5} play style={{ width: 200, height: 200 }} className="lottie" />
    ),
    value: "lottie5",
  },
  {
    label: (
      <Lottie speed={1.5} animationData={lottieJson7} play style={{ width: 200, height: 200 }} className="lottie" />
    ),
    value: "lottie7",
  },
];

const IconList: React.FC = () => {
  const { company } = useMe();
  const [checkedList, setCheckedList] = useState<any[]>(defaultCheckedList);
  const { data, refetch, isLoading, isFetching } = useList({
    resource: "icon-setting",
    config: {
      filters: [
        {
          field: "company_id",
          operator: "eq",
          value: company?.id,
        },
      ],
    },
    successNotification: false,
    errorNotification: false,
  });

  const { mutateAsync: createSetting, isLoading: isCreateLoading } = useCreate();
  const { mutateAsync: updateSetting, isLoading: isUpdateLoading } = useUpdate();

  const icon_settings = data?.data[0];

  const onUpdate = async (list: any[]) => {
    if (icon_settings) {
      await updateSetting({
        resource: "icon-setting",
        values: {
          settings: list,
        },
        id: icon_settings.id!,
      });
    } else {
      await createSetting({
        resource: "icon-setting",
        values: {
          settings: list,
          company_id: company?.id,
        },
      });
    }
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  React.useEffect(() => {
    if (isLoading || isFetching) return;
    setCheckedList(icon_settings?.settings || []);
  }, [isLoading, isFetching, icon_settings]);

  const spinning = isLoading || isFetching || isCreateLoading || isUpdateLoading;
  const onChange = (list: any[]) => {
    onUpdate(list);
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      onUpdate(options.map((item) => item.value));
    } else {
      onUpdate([]);
    }
  };

  return (
    <Spin spinning={spinning}>
      <div>
        <CheckboxGroup
          value={checkedList}
          onChange={onChange}
          className="flex w-full"
          options={options}
          css={css`
            > label {
              width: 30%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          `}
        />
        <Checkbox onChange={onCheckAllChange} checked={checkedList.length === options.length}>
          Check all
        </Checkbox>
      </div>
    </Spin>
  );
};

export default IconList;
