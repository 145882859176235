import React from "react";
import { createRoot } from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import dayjs from "dayjs";
import ReactGA from 'react-ga';
ReactGA.initialize('G-XSEGQ4PX25');
ReactGA.pageview(window.location.pathname + window.location.search);

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
