import React from "react";
import { IActivity, ILevelActivity } from "types/activity";
import { AnswerDataType } from "./AnswerDataType";
import { UserActivityTrackingScore } from "types/user-activity";
import { css } from "@emotion/react";
import { UpdateHistory } from ".";
import { ActionPlanInfo } from "context/CompanyContext";
import { UploadOutlined } from "@ant-design/icons";
import { UploadFiles } from "components/Upload/UploadFile";

interface BlockAnswerCompleteProps {
  item: ILevelActivity;
  isLoadingUpdate: boolean;
  currentLevelActivity?: UserActivityTrackingScore;
  isDisabled: boolean;
  activity: IActivity;
  onComplete: (data: UpdateHistory) => Promise<void>;
  action_plan: ActionPlanInfo;
  ready?: boolean;
}

export const BlockAnswerComplete: React.FC<BlockAnswerCompleteProps> = ({
  onComplete,
  activity,
  item,
  isLoadingUpdate,
  currentLevelActivity,
  isDisabled,
  action_plan,
  ready,
}) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [answer, setAnswer] = React.useState<any>();
  const [files, setFiles] = React.useState<string[]>([]);
  const onSetFiles = (proof: string) => {
    return setFiles((prev) => {
      return [...prev, proof];
    });
  };
  const onRemoveFile = (file: string) => {
    return setFiles((prev) => {
      return prev.filter((f) => f !== file);
    });
  };

  React.useEffect(() => {
    ready && setFiles(currentLevelActivity?.proof || []);
  }, [currentLevelActivity, ready]);

  const _isDisabled = !answer || isDisabled || currentLevelActivity?.completed;
  const isDisabledComplete = files.length > 0 && isLoadingUpdate;
  return (
    <div className="">
      <div className="answer-group" css={css`
        display: flex;
        justify-content: center;
        @media (max-width: 768px) {
          display: grid;
          .complete-button {
            margin-top: 5px;
          }
        }
      `}>
        <AnswerDataType
          isDisabled={isDisabled} 
          data_type={item.data_type}
          item={item}
          setAnswer={setAnswer}
          currentLevelActivity={currentLevelActivity}
        />

        <button
          disabled={isDisabledComplete}
          className={`complete-button ${currentLevelActivity?.completed ? "completed" : ""} ${
            _isDisabled ? "disabled" : ""
          }`}
          onClick={(e) => {
            if (currentLevelActivity?.completed) return;
            onComplete({
              name: item.name,
              completed: !currentLevelActivity?.completed,
              activityId: activity.id,
              levelActivityName: item.name,
              answer,
              proof: files,
            });
          }}
          css={css`
            color: ${action_plan?.background_color};
          `}
        >
          {currentLevelActivity?.completed ? "completed" : "complete"}
        </button>
      </div>
      <div
        className="upload-group"
        css={css`
          button {
            &:hover {
              color: ${action_plan?.border_color} !important;
              border-color: ${action_plan?.border_color} !important;
            }
          }
        `}
      >
        {ready && activity && item && (
          <UploadFiles
            key={files[files.length - 1]}
            isDisabled={_isDisabled}
            loading={loading}
            setLoading={setLoading}
            callback={onSetFiles}
            onRemoveFile={onRemoveFile}
            files={files}
          />
        )}
      </div>
    </div>
  );
};
