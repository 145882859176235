const backend_url = process.env.REACT_APP_BACKEND_URL || "http://localhost:4000";
export const config = {
  API_URL: backend_url + "/api",
  BACKEND_URL: backend_url,
  AAD_CLIENT_ID: process.env.REACT_APP_AAD_CLIENT_ID,
  AAD_TENANT_ID: process.env.REACT_APP_AAD_TENANT_ID,
  DOMAIN: process.env.REACT_APP_DOMAIN || "http://localhost:3000",
  TOKEN_KEY: "tokens",
  USER_KEY: "user-health",
  EMAIL_KEY: process.env.REACT_APP_EMAIL_KEY,
  ASSET_URL: "https://storage.googleapis.com/dev-goodlife2/media/",
  ENV: process.env.REACT_APP_ENV,
  isProduction: process.env.REACT_APP_ENV === "production",
};

export const textAlignHorizontal = ["Left", "Center", "Right"];
export const textAlignVertical = ["Top", "Center", "Bottom"];
export const alignmentTextToCss: any = {
  left: "start",
  center: "center",
  right: "end",
  top: "start",
  bottom: "end",
};

export const textAlignmentHorizontalOps = textAlignHorizontal.map((item) => {
  return { label: item, value: item.toLowerCase() };
});
export const textAlignVerticalOps = textAlignVertical.map((item) => {
  return { label: item, value: item.toLowerCase() };
});
