import React, { useEffect, useState } from "react";
import { useCompany } from "context/CompanyContext";
import { css } from "@emotion/react";
import "./resources.scoped.scss";
//import { css } from "@emotion/react";
//import { Anchor } from 'antd';
import * as itemApi from "../../apis/resourcesApi";
import dec2023 from "../../assets/img/newsletter/newsletter-2023dec.jpg";
import nov2023 from "../../assets/img/newsletter/newsletter-2023nov.jpg";
import oct2023 from "../../assets/img/newsletter/newsletter-2023oct.jpg";
import sep2023 from "../../assets/img/newsletter/newsletter-2023sep.jpg";
import aug2023 from "../../assets/img/newsletter/newsletter-2023aug.jpg";
import jul2023 from "../../assets/img/newsletter/newsletter-2023jul.jpg";
import jun2023 from "../../assets/img/newsletter/newsletter-2023jun.jpg";
import may2023 from "../../assets/img/newsletter/newsletter-2023may.jpg";
import apr2023 from "../../assets/img/newsletter/newsletter-2023apr.png";
import feb2023 from "../../assets/img/newsletter/newsletter-2023feb.png";
import nov2022 from "../../assets/img/newsletter/newsletter-2022nov.png";
import dec2022 from "../../assets/img/newsletter/newsletter-2022dec.png";

import healthcare from "../../assets/img/healthcare.jpg"; 
import food from "../../assets/img/food.jpg"; 
import mental from "../../assets/img/mental.png";
import fitness from "../../assets/img/fitness.jpeg"; 
import { BackTop } from "antd";
const Resources: React.FC = () => {
  const { hra_summary } = useCompany(); 
  const [social, setSocial] = useState<any>([]);
  const [emotional, setEmotional] = useState<any>([]);
  const [environmental, setEnvironmental] = useState<any>([]);
  const [financial, setFinancial] = useState<any>([]);

  const [show, setShow] = useState(true); 
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);

  const fetchSocial = async () => {
    const data = await itemApi.fetchSocial();
    setSocial(data.value);
  };
  const fetchEmotional = async () => {
    const data = await itemApi.fetchEmotional();
    setEmotional(data.value);
  };
  const fetchEnvironmental = async () => {
    const data = await itemApi.fetchEnvironmental();
    setEnvironmental(data.value);
  };
  const fetchFinancial = async () => {
    const data = await itemApi.fetchFinancial();
    setFinancial(data.value);
  };

  useEffect(() => {
    fetchSocial();
    fetchEmotional();
    fetchEnvironmental();
    fetchFinancial();
  }, []);
  return (
    <div className="resources-page pageload" css={css`
      .menu, .green, .newsletter-title, .article-title {
        color: ${hra_summary?.title_color};
      }
      a {
        &:hover {
          color: ${hra_summary?.title_color};
          border-bottom: 3px solid ${hra_summary?.title_color};
        }
        &:active {
          color: ${hra_summary?.title_color};
        }
      }
      hr {
        background-color: ${hra_summary?.title_color};
        border-color: ${hra_summary?.title_color};
      }
      .main-title-title {
        &::before {
          background: ${hra_summary?.title_color} !important;
        }
      }
      @media only screen and (max-width: 600px) {
        .green {
          background-color: ${hra_summary?.title_color};
        }
        .gl-active {
          background-color: ${hra_summary?.title_color} !important;
          color: white !important;
        }
      }
    `}>
      <div>
        <BackTop />
      </div>
      <div className="menu">
        <ul className="menu-content j-between">
          {/* <li className="physical-li"><a href="#physical" className="a">Wellness</a></li> */}
          <li className={show ? "green" : "gray"}
          onClick={() => {
            setShow(true);
            setShow1(false);
            setShow2(false);
            setShow3(false);
            setShow4(false);
          }}>
            <a href="#social" className="a social" id="a-social">
              Healthcare
            </a>
          </li>
          <li className={show1 ? "green" : "gray"}
          onClick={() => {
            setShow(false);
            setShow1(true);
            setShow2(false);
            setShow3(false);
            setShow4(false);
          }}>
            <a href="#emotional" className="a">
              Food
            </a>
          </li>
          <li className={show2 ? "green" : "gray"}
          onClick={() => {
            setShow(false);
            setShow1(false);
            setShow2(true);
            setShow3(false);
            setShow4(false);
          }}>
            <a href="#environmental" className="a">
              Mental Health
            </a>
          </li>
          <li className={show3 ? "green" : "gray"} 
          onClick={() => {
            setShow(false);
            setShow1(false);
            setShow2(false);
            setShow3(true);
            setShow4(false);
          }}>
            <a href="#financial" className="a">
              Fitness
            </a>
          </li>
        </ul>
      </div>
      <div className={`newsletter-res ${show4 ? "gl-active" : "gl-inactive"}`}
          onClick={() => {
            setShow(false);
            setShow1(false);
            setShow2(false);
            setShow3(false);
            setShow4(true);
          }}>
          GoodLife Newsletter
        </div>

      <div className="main-content container j-between">
        <div className="left">
          <div className={show ? 'show-class pageload' : 'hide-class'} id="social"
          >
            <div className="main-title-title">Healthcare</div>
            {social?.map(
              (item: any, index: any) =>
                index < 1 && (
                  <div className="j-between first-article">
                    <div className="artile-main-left">
                      <div className="article-info j-between">
                        <div>{item.datePublished.slice(0, 10)}</div>
                      </div>
                      <a
                        className="article-title article-main-title"
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.name}
                      </a>
                      <p className="res-description">{item.description}</p>
                    </div>
                    <div className="image-main">
                      <a href={item.url} target="_blank" rel="noopener noreferrer">
                        <img src={item.image?.contentUrl ? item.image?.contentUrl : healthcare} alt="" />
                      </a>
                    </div>
                  </div>
                ),
            )}
            <div className="wrapper">
              {social?.map(
                (item: any, index: any) =>
                  index < 7 &&
                  index > 0 && (
                    <div className="item">
                      <div className="image">
                        <a href={item.url} target="_blank" rel="noopener noreferrer">
                          <img src={item.image?.contentUrl ? item.image?.contentUrl : healthcare} alt="" />
                        </a>
                      </div>
                      <div className="small-article-content"> 
                      <div className="article-info j-between">
                        <div>{item.datePublished.slice(0, 10)}</div>
                      </div>
                      <a className="article-title" href={item.url} target="_blank" rel="noopener noreferrer">
                        {item.name}
                      </a>
                      <p>{item.description}</p>
                      </div>
                    </div>
                  ),
              )}
            </div>
          </div>

          <hr />

          <div className={show1 ? 'show-class pageload' : 'hide-class'} id="emotional">
            <div className="main-title-title">Food</div>
            {emotional?.map(
              (item: any, index: any) =>
                index < 1 && (
                  <div className="j-between first-article">
                    <div className="artile-main-left">
                      <div className="article-info j-between">
                        <div>{item.datePublished.slice(0, 10)}</div>
                      </div>
                      <a
                        className="article-title article-main-title"
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.name}
                      </a>
                      <p className="res-description">{item.description}</p>
                    </div>
                    <div className="image-main">
                      <a href={item.url} target="_blank" rel="noopener noreferrer">
                        <img src={item.image?.contentUrl ? item.image?.contentUrl : food} alt="" />
                      </a>
                    </div>
                  </div>
                ),
            )}
            <div className="wrapper">
              {emotional?.map(
                (item: any, index: any) =>
                  index < 7 &&
                  index > 0 && (
                    <div className="item">
                      <div className="image">
                        <a href={item.url} target="_blank" rel="noopener noreferrer">
                          <img src={item.image?.contentUrl ? item.image?.contentUrl : food} alt="" />
                        </a>
                      </div>
                      <div className="small-article-content">
                      <div className="article-info j-between">
                        <div>{item.datePublished.slice(0, 10)}</div>
                      </div>
                      <a className="article-title" href={item.url} target="_blank" rel="noopener noreferrer">
                        {item.name}
                      </a>
                      <p>{item.description}</p>
                      </div>
                    </div>
                  ),
              )}
            </div>
          </div>

          <hr />

          <div className={show2 ? 'show-class pageload' : 'hide-class'} id="environmental">
            <div className="main-title-title">Mental Health</div>
            {environmental?.map(
              (item: any, index: any) =>
                index < 1 && (
                  <div className="j-between first-article">
                    <div className="artile-main-left">
                      <div className="article-info j-between">
                        <div>{item.datePublished.slice(0, 10)}</div>
                      </div>
                      <a
                        className="article-title article-main-title"
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.name}
                      </a>
                      <p className="res-description">{item.description}</p>
                    </div>
                    <div className="image-main">
                      <a href={item.url} target="_blank" rel="noopener noreferrer">
                        <img src={item.image?.contentUrl ? item.image?.contentUrl : mental} alt="" />
                      </a>
                    </div>
                  </div>
                ),
            )}
            <div className="wrapper">
              {environmental?.map(
                (item: any, index: any) =>
                  index < 7 &&
                  index > 0 && (
                    <div className="item">
                      <div className="image">
                        <a href={item.url} target="_blank" rel="noopener noreferrer">
                          <img src={item.image?.contentUrl ? item.image?.contentUrl : mental} alt="" />
                        </a>
                      </div>
                      <div className="small-article-content">
                      <div className="article-info j-between">
                        <div>{item.datePublished.slice(0, 10)}</div>
                      </div>
                      <a className="article-title" href={item.url} target="_blank" rel="noopener noreferrer">
                        {item.name}
                      </a>
                      <p>{item.description}</p>
                      </div>
                    </div>
                  ),
              )}
            </div>
          </div>

          <hr />

          <div className={show3 ? 'show-class pageload' : 'hide-class'} id="financial">
            <div className="main-title-title">Fitness</div>
            {financial?.map(
              (item: any, index: any) =>
                index < 1 && (
                  <div className="j-between first-article">
                    <div className="artile-main-left">
                      <div className="article-info j-between">
                        <div>{item.datePublished.slice(0, 10)}</div>
                      </div>
                      <a
                        className="article-title article-main-title"
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.name}
                      </a>
                      <p className="res-description">{item.description}</p>
                    </div>
                    <div className="image-main">
                      <a href={item.url} target="_blank" rel="noopener noreferrer">
                        <img src={item.image?.contentUrl ? item.image?.contentUrl : fitness} alt="" />
                      </a>
                    </div>
                  </div>
                ),
            )}
            <div className="wrapper">
              {financial?.map(
                (item: any, index: any) =>
                  index < 7 &&
                  index > 0 && (
                    <div className="item">
                      <div className="image">
                        <a href={item.url} target="_blank" rel="noopener noreferrer">
                          <img src={item.image?.contentUrl ? item.image?.contentUrl : fitness} alt="" />
                        </a>
                      </div>
                      <div className="small-article-content">
                      <div className="article-info j-between">
                        <div>{item.datePublished.slice(0, 10)}</div>
                      </div>
                      <a className="article-title" href={item.url} target="_blank" rel="noopener noreferrer">
                        {item.name}
                      </a>
                      <p>{item.description}</p>
                      </div>
                    </div>
                  ),
              )}
            </div>
          </div>
        </div>

        <div  className={`right ${show4 ? 'show-class pageload' : 'hide-class'}`}>
          <div className="newsletter-title">
            Goodlife <div className="align-right">Newsletter</div>
          </div>
          <a
            href="https://storage.googleapis.com/dev-goodlife2/newsletters/GLWS%20Newsletter%20December%20(2).pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={dec2023} alt="newsletter" />
          </a>
          <a
            href="https://storage.googleapis.com/dev-goodlife2/newsletters/GLWS%20Newsletter%20November.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={nov2023} alt="newsletter" />
          </a>
          <a
            href="https://storage.googleapis.com/dev-goodlife2/newsletters/GLWS%20Newsletter%20October%20.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={oct2023} alt="newsletter" />
          </a>
          <a
            href="https://storage.googleapis.com/dev-goodlife2/newsletters/GLWS%20September%20Newsletter%20.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={sep2023} alt="newsletter" />
          </a>
          <div className="vt"></div>
          <a
            href="https://storage.googleapis.com/dev-goodlife2/newsletters/GLWS%20August%20Newsletter.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={aug2023} alt="newsletter" />
          </a>
          <div className="vt"></div>
          <a
            href="https://storage.googleapis.com/dev-goodlife2/newsletters/GLWS%20July%20Newsletter.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={jul2023} alt="newsletter" />
          </a>
          <div className="vt"></div>
          <a
            href="https://storage.googleapis.com/dev-goodlife2/newsletters/GLWS%20June%20Newsletter.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={jun2023} alt="newsletter" />
          </a>
          <div className="vt"></div>
          <a
            href="https://storage.googleapis.com/dev-goodlife2/newsletters/Good%20Life%20Newsletter%20May%202023.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={may2023} alt="newsletter" />
          </a>
          <div className="vt"></div>
          <a
            href="https://storage.googleapis.com/dev-goodlife2/newsletters/pdf-apr2023.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={apr2023} alt="newsletter" />
          </a>
          <div className="vt"></div>
          <a
            href="https://storage.googleapis.com/dev-goodlife2/newsletters/961_Good%20Life%20Newsletter%20Feb%202023.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={feb2023} alt="newsletter" />
          </a>
          <div className="vt"></div>
          <a
            href="https://storage.googleapis.com/dev-goodlife2/newsletters/947_Good%20Life%20Newsletter%20(Dec)%20(1).pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={dec2022} alt="newsletter" />
          </a>
          <div className="vt"></div>
          <a
            href="https://storage.googleapis.com/dev-goodlife2/newsletters/940_Good%20Life%20Newsletter%20(Nov).pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={nov2022} alt="newsletter" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Resources;
