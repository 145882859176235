import {
  useForm,
  Form,
  Input,
  Row,
  Col,
  Create,
  InputNumber,
  DatePicker,
  Checkbox,
  Select,
  useSelect,
} from "@pankod/refine-antd";
import { useNavigation } from "@pankod/refine-core";
import { CardAdmin } from "components/Card/CardAdmin";
import React, { useState } from "react";
import { UploadImage } from "components/Upload/Upload";
import { DataType } from "types/challenge";
import { ListStats } from "./components/ListStats";

export const dataTypeOps = [
  {
    value: DataType.Numeric,
    label: DataType.Numeric,
  },
  { 
    value: DataType["Yes/No"],
    label: DataType["Yes/No"],
  },
  {
    value: DataType.Dropdown,
    label: DataType.Dropdown,
  },
  {
    value: DataType.Date,
    label: DataType.Date,
  },

  {
    value: DataType.FreeText,
    label: DataType.FreeText,
  },
];

export const ChallengeCreate: React.FC = () => {
  const { push } = useNavigation();
  const { formProps, saveButtonProps, form, formLoading } = useForm({
    resource: "challenge",
    redirect: false,
    onMutationSuccess: () => {
      push("/admin/challenge");
    },
  });
  const [loading, setLoading] = useState(false);
  const { selectProps } = useSelect({
    resource: "company",
    optionLabel: "name",
    optionValue: "id",
  });
  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(e: any) => {
          const companies = e.company_ids.map((id: string) => {
            return {
              id,
            };
          });
          formProps.onFinish?.({ ...e, companies });
        }}
      >
        <Row gutter={[64, 0]} wrap>
          <Col xs={24} lg={8}>
            <CardAdmin title="General information">
              <Form.Item
                label={"Company"}
                name={"company_ids"}
                rules={[
                  {
                    required: true,
                  },
                ]}
                className="w-full"
              >
                <Select {...selectProps} mode="multiple" placeholder="Company" />
              </Form.Item>
              <Form.Item
                label={"Challenge name"}
                name="name"
                className="w-[300px]"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="Challenge name" />
              </Form.Item>
              <Form.Item
                className="w-1/2"
                label={"Start date"}
                name="start_date"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker />
              </Form.Item>
              <Form.Item
                className="w-1/2"
                label={"Duration (weeks)"}
                name="duration"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <InputNumber min={0} type="number" />
              </Form.Item>

              <Form.Item
                className="w-1/2"
                label={"Grace period"}
                name="grace_period"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker />
              </Form.Item>
              <Form.Item className="w-1/2" label={"Allow teams"} name="allow_team" valuePropName="checked">
                <Checkbox />
              </Form.Item>
              <Form.Item
                label={"Description"}
                name="description"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input.TextArea />
              </Form.Item>
              <Form.Item
                label={"Header Image"}
                name="header_img"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <UploadImage
                  setLoading={setLoading}
                  loading={loading || formLoading}
                  form={form}
                  isDefault={false}
                  name="header_img"
                />
              </Form.Item>
              <Form.Item
                label={"Scoring Info Image"}
                name="scoring_img"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <UploadImage
                  setLoading={setLoading}
                  loading={loading || formLoading}
                  form={form}
                  isDefault={false}
                  name="scoring_img"
                />
              </Form.Item>
              <Form.Item
                label={"Helpful Hint Image"}
                name="helpful_img"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <UploadImage
                  setLoading={setLoading}
                  loading={loading || formLoading}
                  form={form}
                  isDefault={false}
                  name="helpful_img"
                />
              </Form.Item>
            </CardAdmin>
          </Col>
          <Col xs={24} lg={12}>
            <CardAdmin title="Update stats for each weeks">
              <ListStats />
            </CardAdmin>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
