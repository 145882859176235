import React from "react";
import "../../App.scss";
import "./challengeTables.scoped.scss";
import { useCreate, useList } from "@pankod/refine-core";
import { useCompany } from "context/CompanyContext";
import dayjs from "dayjs";
import { JoinIndividual } from "./JoinIndividual";
import { css } from "@emotion/react";
import { JoinTeam } from "./JoinTeam";
import { CreateTeam } from "./CreateTeam";
import { useMe } from "context/MeContext";

interface ChallengeTablesProps {
  setSelectedChallenge: (value: string | number) => void;
  selectedChallenge: string | number;
  user_challenge?: any;
  refetchUserChallenge?: any;
  challenge?: any;
}

const ChallengeTables: React.FC<ChallengeTablesProps> = ({
  setSelectedChallenge,
  selectedChallenge,
  user_challenge,
  refetchUserChallenge,
  challenge,
}) => {
  const { id } = useCompany();
  const { me } = useMe();
  const { data } = useList({
    resource: "challenge",
    config: {
      filters: [
        {  
          field: "companies.id",
          operator: "eq", 
          value: id,
        },
      ],
    },   
    metaData: {
      join: {
        field: "companies",
      },
    },
  });
  const challenges = data?.data;

  const { mutateAsync: createOrGetUserChallenge, isLoading } = useCreate();
  const onSelectChallenge = async (id: string) => {
    setSelectedChallenge(id);
    await createOrGetUserChallenge({
      resource: "user-challenge",
      values: {
        challenge_id: id,
        userId: me?.id,
      },
      successNotification: false,
    });
    refetchUserChallenge();
  };

  const isJoined = user_challenge?.mode_join;
  return (
    <div
      className="container challenges-table"
      css={css`
        .c-button {
          color: #39e4ed;
          background-color: #37848a;
          text-decoration: none;
          border-radius: 30px;
          padding: 1rem 0;
          width: 30%;
          box-shadow: none;
          border: 0;
          height: auto;
          &:hover {
            color: white;
          }
        }
      `}
    >
      <div className="table-title">CHALLENGES</div>
      <div className="table-content">
        <div className="column-title j-between margin-side">
          <h5 className="align-c column-t align-c-date">Name</h5>
          <h5 className="align-c column-t">Start Date</h5>
          <h5 className="align-c column-t">Action</h5>
        </div>
        {challenges?.map((challenge: any) => {
          const disabled = challenge.id === selectedChallenge || isLoading;
          return (
            <div
              key={challenge.id}
              className="single-challenge"
              onClick={() => !disabled && onSelectChallenge(challenge.id)}
            >
              <hr />
              <div className="j-between margin-side">
                <p className="align-c c-name align-c-date">{challenge.name}</p>
                <p className="align-c c-date">{dayjs(challenge.start_date).format("MM/DD/YYYY")}</p>
                <button className={`align-c c-button select-button`}>Select <span className="select-responsive">This Challenge</span></button>
              </div>
            </div>
          );
        })}

        {!isJoined && user_challenge && (
          <div className="team-options">
            <p style={{ color: "var(--c-blue)", fontSize: 20, fontWeight: "400" }}>
              Please indicate how you would like to join the challenge
            </p>
            <div
              className={`margin-side flex ${challenge?.allow_team ? "j-between" : "justify-center"}`}
              style={{ margin: "0 10%" }}
            >
              <div style={{ width: "45%" }}>
                <JoinIndividual refetchUserChallenge={refetchUserChallenge} id={user_challenge?.id} />
              </div>
              {challenge?.allow_team && (
                <div style={{ width: "45%" }}>
                  <JoinTeam
                    refetchUserChallenge={refetchUserChallenge}
                    id={user_challenge?.id}
                    challengeId={selectedChallenge}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChallengeTables;
