import React from "react";
import { Layout as AntLayout, Grid } from "antd";
import { RefineLayoutLayoutProps } from "@pankod/refine-ui-types";
import { useNavigation, useResource } from "@pankod/refine-core";
import { Layout as LayoutAdmin } from "components/Layout/Admin/Layout";
import { MeProvider, useMe } from "context/MeContext";
import { css } from "@emotion/react";
import { Footer } from "./Footer";
import ScrollToTop from "helpers/ScrollToTop";
import { CompanyProvider } from "context/CompanyContext";

export const Layout: React.FC<RefineLayoutLayoutProps> = (props) => {
  return (
    <MeProvider>
      <CompanyProvider>
        <Body {...props} />
      </CompanyProvider>
    </MeProvider>
  );
};

const Body: React.FC<RefineLayoutLayoutProps> = ({ Sider, Header, children, OffLayoutArea }) => {
  const { me } = useMe();
  const { push } = useNavigation();
  const { resourceName, resource } = useResource();
  React.useEffect(() => {
    if (!me) return;
    if (!me.dateOfBirth || !me.gender) {
      push("/register/social");
    }
  }, [me, push, resourceName]);
  const breakpoint = Grid.useBreakpoint();
  const isAdmin = resource?.options?.route?.includes("admin") || resourceName.includes("admin");
  const isSmall = typeof breakpoint.sm === "undefined" ? true : breakpoint.sm;
  return !isAdmin ? (
    <AntLayout
      style={{ minHeight: "100vh", flexDirection: "row" }}
      css={css`
        background: transparent;
      `}
    >
      {Sider && <Sider />}
      <AntLayout
        css={css`
          background: transparent;
        `}
      >
        {Header && <Header />}
        <AntLayout.Content>
          <div
            style={{
              padding: isSmall ? 24 : 12,
              minHeight: "calc(100vh - 144px)",
            }}
          >
            {children}
          </div>
          {OffLayoutArea && <OffLayoutArea />}
        </AntLayout.Content>
        {<Footer />}
      </AntLayout>
      <ScrollToTop />
    </AntLayout>
  ) : (
    <LayoutAdmin>{children}</LayoutAdmin>
  );
};
