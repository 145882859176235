import { config } from "config";
import { AuthResponse, UserAgentApplication } from "msal";

const redirectUri = `${config.DOMAIN}/auth/microsoft/callback`;
const configAz = {
  auth: {
    clientId: config.AAD_CLIENT_ID || "",
    authority: `https://login.microsoftonline.com/${config.AAD_TENANT_ID}`,
    redirectUri,
    postLogoutRedirectUri: redirectUri,
    navigateToLoginRequestUrl: false,
  },
};

const tokenConfig = {
  scopes: ["User.Read"],
};

export const auth = new UserAgentApplication(configAz);

auth.handleRedirectCallback((error) => {
  if (error) {
    console.error(error);
  }
});

export const getToken = () =>
  auth
    .acquireTokenSilent(tokenConfig)
    .catch(() => auth.acquireTokenRedirect(tokenConfig))
    .then((data) => {
      const _data = data as AuthResponse;
      const id_token = _data.idToken.rawIdToken;
      return id_token;
    });
