import { DeleteButton, EditButton, List, Table, useTable } from "@pankod/refine-antd";
import { useMe } from "context/MeContext";
import React from "react";

export const ChallengeAdmin: React.FC = () => {
  const { company, isSuperAdmin } = useMe();
  const { tableProps } = useTable({
    resource: "challenge",
    permanentFilter: !isSuperAdmin
      ? [
          {
            field: "companies.id",
            operator: "eq",
            value: company?.id,
          },
        ]
      : [],
    metaData: {
      join: {
        field: "companies",
      },
    },
  });
  const columns = [
    {
      title: "Order",
      dataIndex: "order",
      key: "order",
      width: "10%",
    },
    {
      title: "Challenge name",
      dataIndex: "name",
      key: "name",
      width: "30%",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "70%",
    },
    {
      title: "Actions",
      render: (_: any, record: any) => {
        return (
          <div className="flex space-x-2">
            <EditButton shape="circle" hideText recordItemId={record.id} />
            <DeleteButton shape="circle" hideText recordItemId={record.id} resourceNameOrRouteName="challenge" />
          </div>
        );
      },
      width: "10%",
    },
  ];
  return (
    <List title="Challenge" canCreate>
      <Table
        {...tableProps}
        rowKey="id"
        columns={columns}
        pagination={{
          ...tableProps.pagination,
        }}
      />
    </List>
  );
};
