import React, {useState} from "react";
import "../../App.scss";
import "./challengeLeaderboard.scoped.scss";
import { Table, ConfigProvider, List } from "antd";
import top3 from "../../assets/img/top3.png";
import { useChallengeContext } from "context/ChallengeContext";
import { Avatar, Modal, useModal } from "@pankod/refine-antd";
const theme = {
  components: {
    Table: {
      colorTextHeading: "white",
      colorBgContainer: "rgba(76, 144, 149, 0.1)",
      cellPaddingInline: 10,
    }, 
  },
};
const ChallengeLeaderboard: React.FC = () => {
  const [show1, setShow1] = useState(true);
  const [show2, setShow2] = useState(false)
  const { modalProps, close, show } = useModal();
  const { dataIndividual, dataTeam, tablePersonalProps, tableTeamProps, dataRawTeam } = useChallengeContext();
  const top3pp = dataIndividual?.slice(0, 3);
  const [teamIdSelected, setTeamIdSelected] = React.useState(0);
  const teamMember = dataRawTeam?.filter((d) => d.teamId === teamIdSelected);
  return (
    <div className="container leaderboard-challenge">
      <div className="title-part">
        <div className="title">LEADERBOARD</div> 
      </div>
      <div className="challenge-border"></div>
      <div className="top3">
        <img src={top3} alt="" />
        <div className="second-place place">
          <img src={top3pp?.[1]?.user?.icon} alt="" />
        </div>
        <div className="first-place place">
          <img src={top3pp?.[0]?.user?.icon} alt="" />
        </div> 
        <div className="third-place place">
          <img src={top3pp?.[2]?.user?.icon} alt="" />
        </div>
      </div>
      <div className="j-between responsive-group">
        <div className={`reward-reponsive-title left-title ${show1 ? "active-title" : "inactive-title"}`}
          onClick={()=> {
            setShow1(true);
            setShow2(false);
          }}
        >INDIVIDUAL</div>
        <div className={`reward-reponsive-title right-title ${show2 ? "active-title" : "inactive-title"}`}
          onClick={() => {
            setShow2(true);
            setShow1(false);
          }}
        >TEAM</div>
      </div>
      <div className="j-between w-full table-responsive">
        <div className="w-1/2 px-10 table-group">
          <div className="reward-title">INDIVIDUAL</div>
          <ConfigProvider theme={theme}>
            <Table
              {...tablePersonalProps}
              rowKey={"id"}
              dataSource={dataIndividual}
              columns={columns}
              onRow={() => ({
                style: {
                  color: "var(--c-orange)",
                  fontSize: 17,
                },
              })}
              pagination={false}
              className={`table-board ${show1 ? "show-class" : "hide-class"}`}
              tableLayout="fixed"
            />
          </ConfigProvider>
        </div>
        <div className="w-1/2 px-10 table-group">
          {/* <div className="top3"><img src={top3} alt="top 3"/></div> */}
          <div className="reward-title">TEAM</div>
          <ConfigProvider theme={theme}>
            <Table
              {...tableTeamProps}
              rowKey={"id"}
              dataSource={dataTeam}
              columns={columns_all_time}
              onRow={(record) => ({
                style: {
                  color: "var(--c-orange)",
                  fontSize: 17,
                  marginRight: "20px",
                },
                onClick: (e) => {
                  console.log("Clicked", record);
                  setTeamIdSelected(record.teamId);
                  show();
                },
              })}
              pagination={false}
              className={`table-board ${show2 ? "show-class" : "hide-class"}`}
            />
          </ConfigProvider>
          <Modal {...modalProps} title="Team members" onOk={close} centered>
            <List
              dataSource={teamMember}
              renderItem={(item) => (
                <List.Item key={item?.email}>
                  <List.Item.Meta
                    avatar={<Avatar src={item.user?.icon} />}
                    title={item.user?.username}
                    description={item?.email}
                  />
                  <div>{item.point}</div>
                </List.Item>
              )}
            />
          </Modal>
        </div>
      </div>
    </div>
  );
};

const columns = [
  {
    title: "Rank",
    dataIndex: "rank",
    key: "rank",
    width: "100px"
  },
  {
    title: "Username",
    dataIndex: ["user", "username"],
    key: "username",
    width: "300px"
  },
  {
    title: "Points",
    dataIndex: "point",
    key: "point",
    width: "100px"
  },
];

const columns_all_time = [
  {
    title: "Rank",
    dataIndex: "rank",
    key: "rank",
  },
  {
    title: "Team",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Points",
    dataIndex: "point",
    key: "point",
  },
];

export default ChallengeLeaderboard;
