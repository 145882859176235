import React, { createContext, PropsWithChildren, useContext } from "react";

// refine
import { ConfigProvider } from "@pankod/refine-antd";

// configs
import { useLocalStorage } from "hooks/useAuth/useLocalStorage";

interface ColorContextProps {
  color: string;
  setColor: (val: string) => void;
}

const ColorContext = createContext<ColorContextProps>({} as ColorContextProps);

export const useColorContext = () => {
  return useContext(ColorContext);
};

export const ColorProvider: React.FC<Omit<PropsWithChildren<ColorContextProps>, "color" | "setColor">> = ({
  children,
  ...props
}) => {
  const [color, setColor] = useLocalStorage<string>("themeColor", "#0a743d");
  const value = React.useMemo(() => {
    return {
      ...props,
      color,
      setColor,
    };
  }, [color, props, setColor]);

  React.useEffect(() => {
    setColor("#0a743d");
  });

  return (
    <>
      <ColorContext.Provider value={value}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: color,
            },
          }}
        >
          {children}
        </ConfigProvider>
      </ColorContext.Provider>
    </>
  );
};
