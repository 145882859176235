//HEALTHCARE: DONE
export const fetchSocial = async (url, options) => {
  // const res = await fetch(`https://api.bing.microsoft.com/v7.0/news/search?mkt=en-us&q=hasfeed:healthcare&originalImg=true`, {
  // const res = await fetch(`https://api.bing.microsoft.com/v7.0/news?mkt=en-us&category=health&originalImg=true`, {
  const res = await fetch(
    `https://api.bing.microsoft.com/v7.0/news/search?mkt=en-us&q=hasfeed:healthcare&originalImg=true`,
    {
      method: "GET",
      headers: {
        "Ocp-Apim-Subscription-Key": "70c0e10bcd664a388afb19456b8f5fe2",
      },
    },
  );
  const data = await res.json();
  console.log("data news", data);
  return data;
};

//FOOD: DONE
export const fetchEmotional = async (url, options) => {
  // const res = await fetch(`https://api.bing.microsoft.com/v7.0/news/search?mkt=en-us&q=hasfeed:food&originalImg=true`, {
  const res = await fetch(
    `https://api.bing.microsoft.com/v7.0/news/search?mkt=en-us&q=food+hasfeed:nutrition&originalImg=true`,
    {
      method: "GET",
      headers: {
        "Ocp-Apim-Subscription-Key": "70c0e10bcd664a388afb19456b8f5fe2",
      },
    },
  );
  const data = await res.json();
  return data;
};

//MENTAL HEALTH: DONE
export const fetchEnvironmental = async (url, options) => {
  const res = await fetch(
    `https://api.bing.microsoft.com/v7.0/news/search?mkt=en-us&q=hasfeed:mentalhealth&originalImg=true`,
    {
      // const res = await fetch(`https://api.bing.microsoft.com/v7.0/news?mkt=en-us&category=business`, {
      method: "GET",
      headers: {
        "Ocp-Apim-Subscription-Key": "70c0e10bcd664a388afb19456b8f5fe2",
      },
    },
  );
  const data = await res.json();
  return data;
};
//FITNESS: DONE
export const fetchFinancial = async (url, options) => {
  const res = await fetch(
    `https://api.bing.microsoft.com/v7.0/news/search?mkt=en-us&q=hasfeed:fitness&originalImg=true`,
    {
      // const res = await fetch(`https://api.bing.microsoft.com/v7.0/news?mkt=en-us&category=sport`, {
      method: "GET",
      headers: {
        "Ocp-Apim-Subscription-Key": "70c0e10bcd664a388afb19456b8f5fe2",
      },
    },
  );
  const data = await res.json();
  return data;
};
