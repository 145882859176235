import { FormInstance, RcFile, Spin, Upload, UploadFile, UploadProps } from "@pankod/refine-antd";
import { message } from "antd";
import React, { useState } from "react";
import { useLocalStorage } from "hooks/useAuth/useLocalStorage";
import { config } from "config";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import type { UploadChangeParam } from "antd/es/upload";
import empty from "assets/img/empty-image.png";

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

const beforeUpload = (file: RcFile) => {
  const isLt10M = file.size / 1024 / 1024 <= 10;
  if (!isLt10M) {
    message.error("Image must smaller than or equal 10MB!");
  }
  return isLt10M;
};

interface UploadImageProps {
  setLoading: (value: boolean) => void;
  loading: boolean;
  form: FormInstance<{}>;
  url?: string | null;
  isDefault?: boolean;
  name?: string | string[];
}

export const UploadImage: React.FC<UploadImageProps> = ({
  setLoading,
  loading,
  form,
  url = "",
  isDefault = true,
  name = "icon",
}) => {
  const [token] = useLocalStorage<any>(config.TOKEN_KEY, {});

  const [imageUrl, setImageUrl] = useState<string>(url || isDefault ? empty : "");
  React.useEffect(() => {
    setImageUrl(url || "");
  }, [url]);

  const handleChange: UploadProps["onChange"] = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      form.setFieldValue(name, info.file.response);
      form.validateFields();
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  return (
    <Spin spinning={loading}>
      <Upload
        accept="image/*"
        name="file"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        action={`${config.API_URL}/media/upload`}
        beforeUpload={beforeUpload}
        onChange={handleChange}
        headers={{
          Authorization: `Bearer ${token?.accessToken}`,
        }}
      >
        {imageUrl ? <img src={imageUrl} alt="Image" style={{ width: "100%" }} /> : uploadButton}
      </Upload>
    </Spin>
  );
};
