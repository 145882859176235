import { Avatar, DeleteButton, EditButton, List, Table, useTable } from "@pankod/refine-antd";
import dayjs from "dayjs";
import React from "react";

export const CompanyAdmin: React.FC = () => {
  const { tableProps } = useTable({
    resource: "company",
    initialSorter: [
      {
        field: "updatedAt",
        order: "desc",
      },
    ],
  });
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Avatar",
      dataIndex: "icon",
      key: "icon",
      render: (val: string) => {
        return <Avatar src={val} />;
      },
      width: "5%",
    },

    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Path",
      dataIndex: "path",
      key: "path",
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "UpdatedAt",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (val: string) => {
        return <>{dayjs(val).format("YYYY-MM-DD HH:mm:ss")}</>;
      },
    },
    {
      title: "Actions",
      render: (_: any, record: any) => {
        return (
          <div className="flex space-x-2">
            <EditButton shape="circle" hideText recordItemId={record.id} />
            <DeleteButton shape="circle" hideText recordItemId={record.id} resourceNameOrRouteName="company" />
          </div>
        );
      },
      width: "10%",
    },
  ];
  return (
    <List title="Company List" canCreate>
      <Table {...tableProps} rowKey="id" columns={columns} />
    </List>
  );
};
