import React from "react";

import { Authenticated, Refine } from "@pankod/refine-core";
import { notificationProvider, ReadyPage, ErrorComponent } from "@pankod/refine-antd";
import routerProvider from "@pankod/refine-react-router-v6";

// scss
import "./App.scss";
import "@pankod/refine-antd/dist/reset.css";
import "./assets/styles/scss/global.scss";

// pages
import Login from "./pages/Auth/Login/Login";
import Register from "./pages/Auth/Register/Register";
import Dashboard from "./pages/Dashboard";
import SocialRegister from "pages/Auth/SocialRegister/SocialRegister";
import Resources from "pages/Resources";
import PrivacyPolicy from "pages/PrivacyPolicy";
import Terms from "pages/Terms";
import HRA from "pages/HRA";
import MyAccount from "pages/MyAccount";
import ForgotPassword from "pages/Auth/ForgotPassword/ForgotPassword";
import ResetPassword from "pages/Auth/ResetPassword/ResetPassword";
import Challenge from "pages/Challenge";
import ActivityPage from "pages/Activity";

import { authProviderRest } from "./lib/authProvider";
import { Layout } from "components/Layout";

import { Header } from "components/Layout/Header";
import LoginRedirect from "pages/Auth/LoginRedirect";
import { Admin } from "pages/Admin";
import { UserCreate } from "pages/Admin/Users/create";
import { CompanyAdmin } from "pages/Admin/Company";
import { UserEdit } from "pages/Admin/Users/edit";
import { CompanyCreate } from "pages/Admin/Company/create";
import { CompanyEdit } from "pages/Admin/Company/edit";
import { ColorProvider } from "context/ColorContext";
import { QuestionCreate } from "pages/Admin/QuestionDashboard/Question/create";
import { QuestionEdit } from "pages/Admin/QuestionDashboard/Question/edit";
import { QuestionList } from "pages/Admin/QuestionDashboard/Question";
import { Dimension } from "pages/Admin/Dimenson";
import { DimensionCreate } from "pages/Admin/Dimenson/create";
import { DimensionEdit } from "pages/Admin/Dimenson/edit";
import { nestJsDataProvider } from "lib/nestjsCRUDProvider";
import Contact from "pages/Contact";
import { ActivityCreate } from "./pages/Admin/Activity/create";
import { ActivityEdit } from "./pages/Admin/Activity/edit";
import { Trophy } from "pages/Admin/Trophy";
import { TrophyCreate } from "pages/Admin/Trophy/create";
import { TrophyEdit } from "pages/Admin/Trophy/edit";
import { Activity } from "pages/Admin/Activity";
import { ChallengeCreate } from "pages/Admin/Challenge/create";
import { ChallengeEdit } from "pages/Admin/Challenge/edit";
import { ChallengeAdmin } from "pages/Admin/Challenge";
import { Icons } from "pages/Admin/Icons";
import { HRAAdmin } from "pages/Admin/HRAAdmin";
import { ActivityIndividual } from "pages/Admin/ActivityIndividual";
// import { Authenticated } from "@pankod/refine-core"

function App() {
  return (
    <ColorProvider>
      <Refine
        notificationProvider={notificationProvider}
        Layout={Layout}
        DashboardPage={Dashboard}
        LoginPage={Login}
        ReadyPage={ReadyPage}
        catchAll={<ErrorComponent />}
        dataProvider={nestJsDataProvider}
        authProvider={authProviderRest}
        Header={Header}
        options={{
          syncWithLocation: true,
        }}
        routerProvider={{
          ...routerProvider,
          routes: [
            {
              element: <Register />,
              layout: false,
              path: "register",
            },
            {
              element: <SocialRegister />,
              layout: false,
              path: "register/social",
            },
            {
              element: <Login />,
              layout: false,
              path: "login",
            },
            {
              element: <LoginRedirect />,
              path: "auth/:provider/callback",
            },
            {
              element: <Dashboard />,
              path: "/:path",
              layout: true,
            },
            {
              element: <Contact />,
              path: "/:path/contact",
              layout: true,
            },
            {
              element: <ActivityPage />,
              path: "/:path/activity",
              layout: true,
            },
            {
              element: <Resources />,
              path: "/:path/resources",
              layout: true,
            },
            {
              element: <PrivacyPolicy />,
              path: "privacy",
              layout: true,
            },
            {
              element: <Terms />,
              path: "terms",
              layout: true,
            },
            {
              element: (
                <Authenticated>
                  <HRA />
                </Authenticated>
              ),
              path: "/:path/hra",
              layout: true,
            },
            {
              element: <MyAccount />,
              path: "myaccount",
              layout: true,
            },
            {
              element: <ForgotPassword />,
              path: "forgotpassword",
              layout: false,
            },
            {
              element: <ResetPassword />,
              path: "resetpassword",
              layout: false,
            },
            {
              element: <Challenge />,
              path: "/:path/challenge",
              layout: true,
            },
          ],
        }}
        resources={[
          {
            name: "admin",
            list: Admin,
            create: UserCreate,
            edit: UserEdit,
            options: {
              route: "admin",
              label: "User",
            },
          },
          {
            name: "Employer",
            list: CompanyAdmin,
            create: CompanyCreate,
            edit: CompanyEdit,
            options: {
              route: "admin/company",
              label: "Company",
            },
          },
          {
            name: "Question",
            list: QuestionList,
            create: QuestionCreate,
            edit: QuestionEdit,
            options: {
              route: "admin/question",
              label: "Question",
            },
          },
          {
            name: "Dimension",
            list: Dimension,
            create: DimensionCreate,
            edit: DimensionEdit,
            options: {
              route: "admin/dimension",
              label: "Dimension",
            },
          },
          {
            name: "Activity",
            list: Activity,
            create: ActivityCreate,
            edit: ActivityEdit,
            options: {
              route: "admin/activity",
              label: "Activity",
            },
          },
          {
            name: "Challenge",
            list: ChallengeAdmin,
            create: ChallengeCreate,
            edit: ChallengeEdit,
            options: {
              route: "admin/challenge",
              label: "Challenge",
            },
          },
          {
            name: "Trophy",
            list: Trophy,
            create: TrophyCreate,
            edit: TrophyEdit,
            options: {
              route: "admin/trophy",
              label: "Trophy",
            },
          },
          {
            name: "Icons",
            list: Icons,
            options: {
              route: "admin/icons",
              label: "Icons",
            },
          },
          {
            name: "HRAAdmin",
            list: HRAAdmin,
            options: {
              route: "admin/hraadmin",
              label: "Graph Settings",
            },
          },
          {
            name: "ActivityIndividual",
            list: ActivityIndividual,
            options: {
              route: "admin/activityIndividual",
              label: "Activity Individual",
            },
          },
        ]}
      />
    </ColorProvider>
  );
}

export default App;
