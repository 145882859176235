import React, { useState } from "react";
import routerProvider from "@pankod/refine-react-router-v6";
import { RefineLayoutHeaderProps } from "@pankod/refine-ui-types";
import { Layout, Typography, Avatar, Space } from "antd";
import { useLogout, useResource } from "@pankod/refine-core";
import { Dropdown } from "@pankod/refine-antd";
import { SettingOutlined, UserOutlined, LogoutOutlined, QuestionCircleOutlined } from "@ant-design/icons";

import empty from "assets/img/empty_ava.png";

import { useCompany } from "context/CompanyContext";

// css
import "./header.scoped.scss";
import { css } from "@emotion/react";
import tw from "twin.macro";
import { useMe } from "context/MeContext";
import { config } from "config";

const { Link } = routerProvider;
const { Text } = Typography;

export const Header: React.FC<RefineLayoutHeaderProps> = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenSetting, setIsOpenSetting] = useState(false);
  const [isChange, setIsChange] = useState(false);
  const { path: company_path, brand } = useCompany();
  const { me: user, isAdmin, isSuperAdmin } = useMe();
  const icon = (user?.isChangedIcon ? config.ASSET_URL + user?.icon : user?.icon) || empty;
  const { mutate: logout } = useLogout();
  const background_header = brand?.background_color;
  const logo = brand?.logo;
  const borderRadius = brand?.border_radius;
  function Menu() {
    setIsChange((prevState) => !prevState);
    setIsOpen(!isOpen);
  }
  console.log("radius:::", brand?.border_radius);
  const profile = (
    <div
      className={`flex flex-col bg-white rounded-md acc-setting acc-setting-responsive`}
      css={css`
        span {
          ${tw` py-2 px-2 w-full`}
          color : ${background_header};
          display: inline;
        }
        .icon {
          margin: 20px 10px 0 0;
        }
        padding: 2rem;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      `}
    >
      <span className="my-acc">
        <Link to="/myaccount">
          <button
            className="border-gradient border-gradient-green"
            css={css`
              border-color: ${background_header} !important;
              .username {
                color: ${background_header} !important;
              }
            `}
          >
            <Text ellipsis className="username">
              {user?.username}
            </Text>
          </button>
        </Link>
      </span>
      {/* <div className="hr-main">123</div> */}
      {(isAdmin || isSuperAdmin) && (
        <>
          <Link to="/admin" className="item admin-mode lg-ava">
            <span>
              <UserOutlined className="icon" />
              <Text>Admin Mode</Text>
            </span>
          </Link>
          <div className="hr lg-ava"></div>
        </>
      )}
      <Link to="/myaccount" className="item">
        <span>
          <UserOutlined className="icon" />
          <Text>My Profile</Text>
        </span>
      </Link>
      <div className="hr"></div>
      <Link to="/privacy" className="item">
        <span>
          <SettingOutlined className="icon" />
          <Text>Terms & privacy</Text>
        </span>
      </Link>
      <div className="hr"></div>
      <a href="https://monumenthealth.net/goodlife-tutorials/" className="item" target="_blank" rel="noopener noreferrer">
        <span>
          <QuestionCircleOutlined className="icon" />
          <Text>Help & support</Text>
        </span>
      </a>
      <div className="hr"></div>
      {/* <span><MessageOutlined className="icon"/><Text>Give feedback</Text></span> */}
      <span
        className="item"
        onClick={() => {
          logout();
        }}
      >
        <LogoutOutlined className="logout" />
        <Text strong className="shadow-none border-none cursor-pointer">
          Log out
        </Text>
      </span>
    </div>
  );

  const { resourceName, resource } = useResource();
  const arrSecondary = ["contact", "resource", "privacy", "terms", "hra", "myaccount", "activity"];
  const shouldShowSecondaryHeader = arrSecondary.some(
    (item) => resource?.options?.route?.includes(item) || resourceName.includes(item),
  );

  return (
    <Layout.Header
      className={`j-between ${shouldShowSecondaryHeader ? "p-0" : ""}`}
      style={{
        alignItems: "center",
        margin: "0",
        zIndex: "10",
        width: "100%",
        height: "auto",
        backgroundColor: "transparent",
        padding: "0",
      }}
      css={css`
        .header-bg { 
          background-color: ${background_header};
          li {
            color: white !important;
          }
          .logologo img {
            ${(company_path === "htop" || company_path === "marshall") ? "filter: brightness(0) invert(1);" : "filter: brightness(1) invert(0);"}
          }
        }
        li {
          color: ${brand?.navbar_color};
          font-size: ${brand?.navbar_fontsize}px !important;
        }
        }
      `}
    >
      <div className={`w-full ${shouldShowSecondaryHeader ? "header-bg" : "header-bg1"}`}>
        <div className={`j-between menu ${shouldShowSecondaryHeader ? "secondary nav-menu" : ""} container`}>
          <div className="d51logo">
            <Link to={`${company_path}`} className="logologo">
              <img
                src={logo}
                alt="logo"
                className="logo"
                css={css`
                  ${borderRadius === true ? "border-radius: 10px;" : ""}
                `}
              />
            </Link>
          </div>
          <div className="navbar-container stroke">
            <ul
              className={`navbar j-between menumenu responsive-navbar ${isOpen ? "open" : ""} ${
                isOpenSetting ? "open-setting-padding" : "open-setting"
              }`}
              css={css`
                @media (max-width: 577px) {
                  background-color: ${brand?.color_primary} !important;
                }
                @media (max-width: 700px) {
                  li {
                    font-size: 12px !important;
                  }
                }
                @media (min-width: 600px) and (max-width: 740px) {
                  margin-right: 200px !important;
                  width: 50% !important;
                  li {
                    font-size: 10px !important;
                  }
                }
              `}
            >
              <Link to={`${company_path}`} onClick={Menu}>
                <li>DASHBOARD</li>
              </Link>
              {/* <Link to={`${company_path}/activity`} onClick={Menu}>
                <li>ACTIVITY</li>
              </Link> */}
              <Link to={`${company_path}/challenge`} onClick={Menu}>
                <li>CHALLENGES</li>
              </Link>
              <Link to={`${company_path}/hra`} onClick={Menu}>
                <li>HRA </li>
              </Link>
              <Link to={`${company_path}/resources`} onClick={Menu}>
                <li>RESOURCES</li>
              </Link>
              <Link to={`${company_path}/contact`} onClick={Menu}>
                <li>CONTACT</li>
              </Link>
              {/* <Dropdown className="dropdown dd-rs-sm" overlay={profile} placement="bottomRight">
                <Space>
                  <li className="setting-privacy" onClick={() => setIsOpenSetting(!isOpenSetting)}>
                    SETTINGS & PRIVACY
                  </li>
                </Space>
              </Dropdown> */}
            </ul>
          </div>
          <div
            className={`menu-rs ${isChange ? "menu-change" : ""}`}
            onClick={Menu}
            css={css`
              .change .bar1 {
                background-color: ${brand?.color_primary};
              }
              .change .bar3 {
                background-color: ${brand?.color_primary};
              }
              .menu-change {
                color: ${brand?.color_primary} !important;
              }
              right: 50px !important;
            `}
          >
            <div className={`bars ${isChange ? "change" : ""}`}>
              <div className="bar1"></div>
              <div className="bar2"></div>
              <div className="bar3"></div>
            </div>
            MENU
          </div>
          <Dropdown className="ml-8 dropdown dd-rs-md" overlay={profile} placement="bottomRight">
            <Space>
              <Avatar size="large" src={icon} alt={user?.firstname + " " + user?.lastname} className="lg-ava" />
              <Avatar src={icon} alt={user?.firstname + " " + user?.lastname} className="sm-ava" />
            </Space>
          </Dropdown>
        </div>
      </div>
    </Layout.Header>
  );
};
